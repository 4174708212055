import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import md5 from "js-md5";
import { v4 as uuidv4 } from "uuid";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";

import NewsDetailsViewDesktop from "./news-details-view-desktop";
import NewsDetailsViewTablet from "./news-details-view-tablet";
import NewsDetailsViewPhone from "./news-details-view-phone";

import { getLocaleValue } from "../../../../helpers/get-locale-value";
import { getLocaleValueFromArrayWithName } from "../../../../helpers/get-locale-value-from-array-by-name";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";
import { getMetaDetails } from "../../../../helpers/get-meta-details";

const NewsDetailsController = ({ location, pageContext }) => {
  const {
    categories,
    newspage,
    post,
    header,
    footer,
    siteSetting,
    reloadRequired,
    meta_details_variants,
  } = pageContext;

  const { page_title_variants: pageTitleVariants } = newspage;

  const [threadState, setThread] = useState();
  const [forumSecretState, setForumSecret] = useState();
  const [isLoadingState, setIsLoading] = useState(true);

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  useEffect(() => {
    const targetVariant = getLocaleValue(locale, post.variants);
    getForumThreadDetails(targetVariant);
  }, [locale]); // on changed

  const getForumThreadDetails = async variant => {
    setIsLoading(true);

    if (variant.forum_thread_id) {
      const clientname = "ro_cms";
      const clientversion = "1.0.0";
      const platformname = "cms";
      const platformversion = "1.0.0";

      try {
        const thread = await axios({
          method: "get",
          url: "",
          baseURL: `${process.env.GATSBY_FORUM_API_URL}`,
          params: {
            api_m: "api_init",
            clientname,
            clientversion,
            platformname,
            platformversion,
            uniqueid: md5(
              `${clientname}${clientversion}${platformname}${platformversion}${uuidv4()}`
            ),
          },
        }).then(result => {
          const { data } = result;
          setForumSecret(data);

          return axios({
            method: "get",
            url: "",
            baseURL: `${process.env.GATSBY_FORUM_API_URL}`,
            params: {
              api_m: "vb4_showthread.call",
              api_c: data.apiclientid,
              api_s: data.apiaccesstoken,
              threadid: variant.forum_thread_id,
              api_sig: md5(
                `api_m=vb4_showthread.call&threadid=${variant.forum_thread_id}${data.apiaccesstoken}${data.apiclientid}${data.secret}${process.env.GATSBY_FORUM_API_KEY}`
              ),
            },
          });
        });

        setIsLoading(false);
        setThread(thread.data.response);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      console.warn("This news is not linked to forum.");
    }
  };

  const newsPost = getLocaleValue(locale, post.variants);
  let seoData = siteSetting;
  seoData.site_meta_image = newsPost.banner;
  seoData.site_name = newsPost.title;
  seoData.site_description = newsPost.title;

  seoData = getMetaDetails(locale, meta_details_variants, siteSetting, seoData);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <NewsDetailsViewDesktop
          locale={locale}
          data={post}
          isThreadLoading={isLoadingState}
          thread={threadState}
          forumSecret={forumSecretState}
          location={location}
          header={header}
          footer={footer}
          siteSetting={seoData}
          categories={categories}
          announced_at={post.announced_at}
          pageTitleVariants={pageTitleVariants}
          reloadRequired={reloadRequired}
        />
      }
      TabletComponent={
        <NewsDetailsViewTablet
          locale={locale}
          data={post}
          isThreadLoading={isLoadingState}
          thread={threadState}
          forumSecret={forumSecretState}
          location={location}
          header={header}
          footer={footer}
          siteSetting={seoData}
          categories={categories}
          announced_at={post.announced_at}
          pageTitleVariants={pageTitleVariants}
          reloadRequired={reloadRequired}
        />
      }
      PhoneComponent={
        <NewsDetailsViewPhone
          locale={locale}
          data={post}
          isThreadLoading={isLoadingState}
          thread={threadState}
          forumSecret={forumSecretState}
          location={location}
          header={header}
          footer={footer}
          siteSetting={seoData}
          categories={categories}
          announced_at={post.announced_at}
          pageTitleVariants={pageTitleVariants}
          reloadRequired={reloadRequired}
        />
      }
    />
  );
};
export default NewsDetailsController;
