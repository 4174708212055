import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Parser from "html-react-parser";
import { BiCalendarCheck } from "@react-icons/all-files/bi/BiCalendarCheck";
import { MdMessage } from "@react-icons/all-files/md/MdMessage";

import { Box, Typography } from "@material-ui/core";
import Moment from "react-moment";
import "moment/locale/zh-cn";
import "moment/locale/th";
import "moment/locale/id";
import "moment/locale/es";
import "moment/locale/pt";

import CommentController from "./comment/comment-controller";
import { getLocaleValueFromArrayByName } from "../../../../../helpers/get-locale-value-from-array-by-name";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles({
  wrapper: {
    borderBottom: "1px solid #ddd",
    padding: "15px",
    overflow: "hidden",
    display: "flex",
  },

  bold: {
    fontWeight: "bold",
  },

  userInfo: {
    position: "relative",
    paddingRight: "35px",
    width: "130px",
    wordBreak: "break-word",
    textAlign: "center",
    flexShrink: "0",

    "&:after, &:before": {
      content: '""',
      display: "block",
      position: "absolute",
    },

    "&:before": {
      right: "10px",
      width: 1,
      height: "130%",
      background: "#ddd",
    },

    "&:after": {
      width: "8px",
      height: "8px",
      borderTop: "1px solid #ddd",
      borderRight: "1px solid #ddd",
      transform: "rotate(45deg)",
      right: "6px",
      top: "30px",
      background: "#fff",
    },
  },

  commentWrapper: {
    border: "1px dashed #ddd",
    backgroundColor: "#fafafa",
    borderRadius: "5px",
  },

  avatarImage: {
    width: "100px",
    border: "3px solid #efefef",
  },
});

const ForumItemViewTablet = ({
  locale,
  data,
  isCommentLoading,
  comments,
  location,
  copywritingVariants,
}) => {
  const { posttime, username, avatarurl, commentcount } = data.post;
  const classes = useStyles();

  let message_html = data.post.message_html;
  message_html = message_html.replaceAll(
    '"filedata/fetch?id=',
    "http://forum.ragnaroketernallove.com/filedata/fetch?id="
  );

  //since the timestamp returned is missing the last 3 digits, the miliseconds of the time, we just append it in to get the correct time stamp
  const postDate = new Date(parseInt(posttime + "000"));

  return (
    <Box mt={1}>
      <Box px={2}>
        <Box className={classes.wrapper}>
          <Box className={classes.userInfo}>
            <img
              className={classes.avatarImage}
              src={`https://forum.ragnaroketernallove.com/${avatarurl}`}
            />
            <Typography className={`text-secondary ${classes.bold}`}>
              {username}
            </Typography>
            {/* <Typography variant="body2" className={`${classes.bold}`}>
              {user.member_status}
            </Typography> */}
            <Box mt={5} fontSize="0.8em">
              {/* <Box>
                Join Date:{" "}
                <Moment
                  locale={locale}
                  format="MMMM YYYY"
                  date={user.join_date}
                />
              </Box>
              <Box>Posts: {user.post_count}</Box> */}
            </Box>
          </Box>
          <Box width="100%">
            <Box py={1} px={2.5} pl={3.5} fontSize="0.9em">
              <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                color="#6d6d6d"
                fontStyle="italics"
                fontSize="0.85em"
              >
                <Box color="#777777" fontSize="1.2em" lineHeight="1em">
                  <BiCalendarCheck />
                </Box>

                <Box ml={0.5} mt={0.15}>
                  <Moment
                    locale={locale}
                    format="DD MMMM YYYY, hh:mm A"
                    date={postDate}
                  />
                </Box>
              </Box>

              <Box>{Parser(message_html)}</Box>

              {parseInt(commentcount) > 0 && (
                <Box mt={2}>
                  <Box p={1} className={classes.commentWrapper}>
                    <Box display="flex" alignItems="center" color="#777">
                      <MdMessage />
                      <Box ml={0.5} mt={0.15} fontStyle="normal">
                        {copywritingVariants &&
                          getLocaleValueFromArrayByName(
                            locale,
                            copywritingVariants,
                            "copywriting-comments"
                          ).content}{" "}
                      </Box>
                    </Box>

                    {isCommentLoading ? (
                      <Box
                        border="1px solid #eee"
                        style={{ background: "#fff" }}
                        p={2}
                        mt={1}
                      >
                        <Box display="flex">
                          <Box flexShrink="0">
                            <Skeleton variant="rect" width={50} height={50} />
                          </Box>

                          <Box width="100%" ml={2}>
                            <Skeleton />
                            <Skeleton />
                          </Box>
                        </Box>
                        <Box mt={3} display="flex">
                          <Box flexShrink="0">
                            <Skeleton variant="rect" width={50} height={50} />
                          </Box>

                          <Box width="100%" ml={2}>
                            <Skeleton />
                            <Skeleton />
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        {comments?.map((n, i) => (
                          <CommentController
                            key={i}
                            data={n}
                            location={location}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ForumItemViewTablet;
