import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import ForumItemController from "./forum-item-controller";

import { Box } from "@material-ui/core";
import { getLocaleValueFromArrayByName } from "../../../../../helpers/get-locale-value-from-array-by-name";

const useStyles = makeStyles({
  forumWrapper: {
    border: "1px solid #eee",
    borderTop: "0px",
    backgroundColor: "#fff",
  },
});

const ForumView = ({ locale, data, forumSecret, location, threadId }) => {
  const classes = useStyles();

  const copywriting_variants = useSelector(state => {
    return state.siteSettingReducer.copywriting_variants;
  }, []);

  if (data && data.postbits?.length > 0) {
    data.postbits.shift(); // remove first element
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      className={classes.forumWrapper}
      pb={2}
    >
      {data && data.postbits?.length > 0 ? (
        data.postbits?.map((n, i) => (
          <ForumItemController
            key={i}
            data={n}
            forumSecret={forumSecret}
            location={location}
          />
        ))
      ) : (
        <Box
          display="flex"
          p={3}
          justifyContent="center"
          color="#777"
          borderBottom="1px solid #e8e8e8"
        >
          {copywriting_variants &&
            getLocaleValueFromArrayByName(
              locale,
              copywriting_variants,
              "copywriting-nodiscussion"
            )?.content}
        </Box>
      )}

      {(threadId || data) && (
        <Box px={2} mt={2} display="flex" justifyContent="flex-end">
          <a className={`button`} href={data?.thread?.threadurl}>
            {copywriting_variants &&
              getLocaleValueFromArrayByName(
                locale,
                copywriting_variants,
                "copywriting-discuss"
              ).content}
          </a>
        </Box>
      )}
    </Box>
  );
};
export default ForumView;
