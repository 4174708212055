import React from "react";
import { useSelector } from "react-redux";

import ResponsiveWrapper from "../../../../shared-components/responsive-wrapper";

import ForumView from "./forum-view";

const ForumController = ({ data, forumSecret, location, threadId }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <ForumView
          locale={locale}
          data={data}
          forumSecret={forumSecret}
          location={location}
          threadId={threadId}
        />
      }
      TabletComponent={
        <ForumView
          locale={locale}
          data={data}
          forumSecret={forumSecret}
          location={location}
          threadId={threadId}
        />
      }
      PhoneComponent={
        <ForumView
          locale={locale}
          data={data}
          forumSecret={forumSecret}
          location={location}
          threadId={threadId}
        />
      }
    />
  );
};
export default ForumController;
