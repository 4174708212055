import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import md5 from "js-md5";
import { v4 as uuidv4 } from "uuid";
import ResponsiveWrapper from "../../../../shared-components/responsive-wrapper";

import ForumItemViewDesktop from "./forum-item-view-desktop";
import ForumItemViewTablet from "./forum-item-view-tablet";
import ForumItemViewPhone from "./forum-item-view-phone";

const ForumItemController = ({ data, forumSecret, location }) => {
  const [commentsState, setComments] = useState([]);
  const [isLoadingState, setIsLoading] = useState(true);

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const copywriting_variants = useSelector(state => {
    return state.siteSettingReducer.copywriting_variants;
  }, []);

  const getPostDetails = async post => {
    setIsLoading(true);

    const { data: commentsObject } = await axios({
      method: "get",
      url: "",
      baseURL: `${process.env.GATSBY_FORUM_API_URL}`,
      params: {
        api_m: "node.listNodeContent",
        api_c: forumSecret.apiclientid,
        api_s: forumSecret.apiaccesstoken,
        parentid: post.postid,
        api_sig: md5(
          `api_m=node.listNodeContent&parentid=${post.postid}${forumSecret.apiaccesstoken}${forumSecret.apiclientid}${forumSecret.secret}${process.env.GATSBY_FORUM_API_KEY}`
        ),
      },
    });

    const commentArray = [];

    for (const property in commentsObject) {
      commentArray.push(commentsObject[property]);
    }
    setComments(commentArray);
  };

  useEffect(() => {
    const { post } = data;

    if (post.commentcount > 0) {
      try {
        getPostDetails(post);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [data]); // on changed

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <ForumItemViewDesktop
          locale={locale}
          isCommentLoading={isLoadingState}
          comments={commentsState}
          data={data}
          copywritingVariants={copywriting_variants}
          location={location}
        />
      }
      TabletComponent={
        <ForumItemViewTablet
          locale={locale}
          isCommentLoading={isLoadingState}
          comments={commentsState}
          data={data}
          copywritingVariants={copywriting_variants}
          location={location}
        />
      }
      PhoneComponent={
        <ForumItemViewPhone
          locale={locale}
          isCommentLoading={isLoadingState}
          comments={commentsState}
          data={data}
          copywritingVariants={copywriting_variants}
          location={location}
        />
      }
    />
  );
};
export default ForumItemController;
