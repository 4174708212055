import React from "react";
import { useSelector } from "react-redux";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

import Header from "../../../shared-components/header/header-controller";
import Footer from "../../../shared-components/footer/footer-controller";

import Parser from "html-react-parser";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import { getLocaleValueFromArrayByName } from "../../../../helpers/get-locale-value-from-array-by-name";

import NewsCategoryListController from "../../category/news-category-list-controller";
import ForumController from "./forum/forum-controller";

import { Box, Typography } from "@material-ui/core";
import Moment from "react-moment";
import "moment/locale/zh-cn";
import "moment/locale/th";
import "moment/locale/id";
import "moment/locale/es";
import "moment/locale/pt";

import Image from "../../../shared-components/image";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";
import Skeleton from "@material-ui/lab/Skeleton";
import SEO from "../../../shared-components/seo";

const useStyles = makeStyles({
  row: {
    borderBottom: "1px solid #ddd",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
  discussionHeader: {
    backgroundColor: "#eaeaea",
    borderRadius: "10px 10px 0 0",
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  bannerImage: {
    border: "10px solid #ddd",
    borderRadius: "20px",
  },
});

const NewsDetailsViewDesktop = ({
  locale,
  data,
  isThreadLoading,
  thread,
  forumSecret,
  location,
  header,
  footer,
  siteSetting,
  categories,
  announced_at,
  pageTitleVariants,
  reloadRequired,
}) => {
  const { variants, news_category } = data;

  const classes = useStyles();
  const newsPost = getLocaleValue(locale, variants);
  const updated_date = new Date(announced_at);

  const copywriting_variants = useSelector(state => {
    return state.siteSettingReducer.copywriting_variants;
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
    >
      <SEO siteSetting={siteSetting} />

      <Header
        header={header}
        siteSetting={siteSetting}
        location={location}
        reloadRequired={reloadRequired}
      />
      <Container style={{ flex: 1 }}>
        <Box py={8}>
          <Typography variant="h4" className={classes.row}>
            {getLocaleValue(locale, pageTitleVariants).content}
          </Typography>

          <Box width="100%" display="flex">
            <Box flexShrink="0">
              <NewsCategoryListController
                categories={categories}
                location={location}
                currentNewsCategory={news_category}
              />
            </Box>
            <Box px={2} width="100%">
              {/* News post section */}

              <Image
                className={classes.bannerImage}
                imageObject={newsPost.banner}
              />
              <Box width="100%" px="30px">
                <Box textAlign="right" my={2}>
                  {copywriting_variants &&
                    getLocaleValueFromArrayByName(
                      locale,
                      copywriting_variants,
                      "copywriting-postedon"
                    ).content}{" "}
                  <Moment
                    locale={locale}
                    format={
                      locale.toLowerCase() == "zh-cn" ? "LL" : "DD MMM YYYY"
                    }
                    date={updated_date}
                  />
                </Box>

                <Box width="100%" mb={2}>
                  <Typography variant="h6">{newsPost.title}</Typography>
                </Box>

                <Box mt={3} width="100%">
                  {Parser(newsPost.html_full_content)}
                </Box>
              </Box>

              {/* Comments section - Commenting off till backend ready */}
              {newsPost.forum_thread_id && (
                <Box mt={3} px="30px">
                  <Box py={1} px={2} className={classes.discussionHeader}>
                    {copywriting_variants &&
                      getLocaleValueFromArrayByName(
                        locale,
                        copywriting_variants,
                        "copywriting-discussboard"
                      ).content}
                  </Box>
                  {isThreadLoading ? (
                    <Box border="1px solid #ddd" p={2}>
                      <Box display="flex">
                        <Box flexShrink="0">
                          <Skeleton variant="rect" width={100} height={100} />
                        </Box>

                        <Box width="100%" ml={2}>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                        </Box>
                      </Box>
                      <Box mt={3} display="flex">
                        <Box flexShrink="0">
                          <Skeleton variant="rect" width={100} height={100} />
                        </Box>

                        <Box width="100%" ml={2}>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <ForumController
                      data={thread}
                      forumSecret={forumSecret}
                      threadId={newsPost.forum_thread_id}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer footer={footer} siteSetting={siteSetting} />
    </Box>
  );
};
export default NewsDetailsViewDesktop;
