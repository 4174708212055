import React from "react";
import { useSelector } from "react-redux";
import ResponsiveWrapper from "../../../../../shared-components/responsive-wrapper";

import CommentViewDesktop from "./comment-item-view-desktop";
import CommentViewPhone from "./comment-item-view-phone";
import CommentViewTablet from "./comment-item-view-tablet";

const CommentController = ({ data, location, pageContext }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <CommentViewDesktop locale={locale} data={data} location={location} />
      }
      TabletComponent={
        <CommentViewTablet locale={locale} data={data} location={location} />
      }
      PhoneComponent={
        <CommentViewPhone locale={locale} data={data} location={location} />
      }
    />
  );
};
export default CommentController;
