import React from "react";
import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { BiCalendarCheck } from "@react-icons/all-files/bi/BiCalendarCheck";

import Parser from "html-react-parser";
import Moment from "react-moment";
import "moment/locale/zh-cn";
import "moment/locale/th";
import "moment/locale/id";
import "moment/locale/es";
import "moment/locale/pt";

const useStyles = makeStyles({
  commentWrapper: {
    border: "1px solid #eee",
    backgroundColor: "#fefefe",
  },
  avatarImage: {
    width: "50px",
    border: "3px solid #efefef",
  },
  bold: {
    fontWeight: "bold",
  },
});

const CommentViewTablet = ({ locale, data, location }) => {
  const classes = useStyles();

  const { avatar, authorname, publishdate, rawtext } = data.content;
  const postDate = new Date(parseInt(publishdate + "000"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      className={classes.commentWrapper}
      p={1}
      mt={1}
    >
      <Box display="flex" alignItems="center" fontSize="0.9em">
        <Box display="flex" alignItems="center" mr={1}>
          <img
            className={classes.avatarImage}
            src={`https://forum.ragnaroketernallove.com/${
              avatar.hascustom > 0
                ? avatar.avatarpath
                : `core/${avatar.avatarpath}`
            }`}
          />
        </Box>
        <Box>
          <Typography className={`text-secondary ${classes.bold}`}>
            {authorname}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            color="#6d6d6d"
            fontStyle="italics"
            fontSize="0.85em"
          >
            <Box color="#777777" fontSize="1.2em" lineHeight="1em">
              <BiCalendarCheck />
            </Box>

            <Box ml={0.5} mt={0.15}>
              <Moment
                locale={locale}
                format="DD MMMM YYYY, hh:mm A"
                date={postDate}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={1}>{Parser(rawtext)}</Box>
    </Box>
  );
};
export default CommentViewTablet;
